import * as React from "react";
import { Layout } from "app/components/layout";
import { Header1 } from "app/components/header";
import { Footer1 } from "app/layouts/footer1";

const TermsAndConditionsOfSale: React.FC = () => (
  <Layout>
    <div className="bg-white pb-8 sm:pb-12 lg:pb-12 font-sans">
      <Header1 />
      <div className="max-w-3xl m-9">
        <h1>Terms and Conditions of Sale</h1>
        <h2>Terms and Conditions of Sale</h2>
        <p>
          These terms and conditions of sale ("terms and conditions" or "agreement") are agreed
          between Qameta Software s.r.o., or its affiliate, whichever is the reseller ("reseller")
          and the entity ("customer") to which seller is making a sale ("sale") of one or more
          "sales offerings" (meaning products, product support and services) under a "transaction
          document" (meaning a purchase order or statement of work) and form an agreement,
          exclusively governing such sale effective on the earlier of the date when such transaction
          document is "executed" (meaning, with respect to a purchase order, the date on which such
          purchase order is accepted by seller, and with respect to a statement of work, the date
          that is identified as the effective date, or in the absence of such identified effective
          date, when the statement of work is countersigned) and the date when seller initiates
          performance hereunder at customer's request ("agreement effective date"). These terms and
          conditions may be updated from time to time by seller without prior notice; provided that
          the version of these terms and conditions posted on this website at the time the
          transaction document is executed shall be the agreement that governs such transaction
          document. Notwithstanding the foregoing, if the parties have entered into a separate,
          effective agreement applicable to such transaction document ("separate agreement"), then
          these terms and conditions shall not apply to such transaction document and shall not
          govern such sale, and instead such separate agreement shall govern such sale.
        </p>
        <p>
          In the event of a conflict between these terms and conditions and a separate agreement,
          the separate agreement shall prevail. In the event of a conflict between the transaction
          document and these terms and conditions, these terms and conditions shall prevail, and any
          additional or different terms or conditions in any form associated with the transaction
          document or otherwise provided by customer, other than the "transaction specifics"
          (meaning the numerical and other identifying information necessary to the sale, including
          without limitation price, quantity, product names and/or codes, and delivery and/or
          invoice addresses), are hereby deemed to be material alterations of these terms and
          conditions, are hereby rejected, and are null and void; provided that conflicting terms
          and conditions in a statement of work that expressly state that they supersede certain
          specified provisions of these terms and conditions shall prevail.
        </p>
        <p>
          aAs used herein, "party" means seller or customer individually, and "parties" means seller
          and customer collectively.
        </p>
        <h2>PRODUCT AND PRODUCT SUPPORT SALES.</h2>
        <p>
          Product and Product Support Purchasing. Subject to credit approval, Customer shall be
          entitled to purchase hereunder for its own internal use and not for resale: (a) hardware,
          software, and other items that are commercially available in Seller's catalog (each a
          "Product"); and (b) warranties, maintenance, and other services that are resold by Seller
          and that are performed by a third party under the terms of a separate contract exclusively
          between Customer and such third party ("Product Support"). With respect to Product
          Support, Customer acknowledges and agrees that such third party is the contracting party,
          that such third party is exclusively responsible for performing the Product Support, and
          that Customer hereby releases and shall have no claim against Seller, Seller's Affiliates
          or Seller's Personnel for any loss, claims or damages arising from, or related to, such
          Product Support.
        </p>
        <p>
          Product and Product Support Quotes. Seller may from time to time provide quotes to
          Customer, orally or in writing, specifying Transaction Specifics related to identified
          Products or Product Support ("Quote") that may be the subject of a Sale hereunder. Any
          Quote is presented for reference purposes only and shall not be deemed to be an offer.
        </p>
        <p>
          Product and Product Support Pricing. Any pricing is subject to change, except pricing that
          appears in a "Purchase Order" or "PO" (meaning an order for Sales Offerings submitted by
          Customer to Seller either in hard copy or electronic form, including via Seller's website,
          in accordance with these Terms and Conditions) that is accepted by Seller in writing
          ("Confirmed Pricing"). Other than with respect to Confirmed Pricing, Seller reserves the
          right to make adjustments to pricing, Products and Product Support for reasons including,
          but not limited to, changes in market conditions or manufacturer price, or Product
          unavailability or discontinuation. Notwithstanding the foregoing, Seller shall have the
          right to terminate, and shall not be obligated to perform under, any accepted Purchase
          Order in the event that the Confirmed Pricing associated therewith was based on an error
          in any advertisement or Quote.
        </p>
        <p>
          Products and Product Support POs. Customer makes an offer to purchase Products or Product
          Support hereunder by submitting the Purchase Order to Seller. Customer also may request
          Products or Product Support via telephone; provided that Customer confirms its offer
          promptly thereafter by submitting the Purchase Order to Seller. Seller agrees to fill any
          Purchase Order upon Seller's written acceptance of such Purchase Order; provided that: (a)
          any such acceptance by Seller is subject to Product availability; and (b) any delivery
          dates specified in such Purchase Order are non-binding; and (c) Seller is obligated only
          to make good faith efforts to meet such delivery dates. Each Purchase Order for Products
          and/or Product Support shall specify, as applicable, the quantities and prices specified
          on Seller's Quote, along with destination, preferred (but non-binding) delivery date, and
          any non-standard shipping instructions. Nothing stated in Customer's Purchase Order or
          other forms shall in any way be deemed to add to, modify, or amend these Terms and
          Conditions. Any terms or conditions attached to or set forth or referenced in any Purchase
          Order shall be of no force or effect.
        </p>
        <p>
          Product Delivery. Seller shall make commercially reasonable efforts to meet any requested
          delivery dates, but Seller shall not be liable for any delay or inability to ship Products
          due to Product unavailability, manufacturer delays, or any Force Majeure Event. Seller
          shall have the right to make two (2) or more partial deliveries under a single Purchase
          Order to complete delivery of all Products ordered thereunder.
        </p>
        <p>
          Product Title. Title to Products and risk of Product loss or damage pass to Customer upon
          delivery to Customer. Notwithstanding the foregoing, title to software Products
          manufactured by a third party shall at all times remain with such third party, and
          Customer's rights to any such software Products shall be as specified in the applicable
          software license agreement between Customer and such third party ("EULA"); Customer
          warrants that it shall not download or use any software Products without first entering
          into the applicable EULA.
        </p>
        <p>
          Product and Product Support Invoicing. Seller will invoice Customer for Products at the
          time of shipment and for Product Support at the time of purchase. Seller may invoice
          Customer separately for partial deliveries. Any amounts invoiced by Seller with respect to
          Product Support, including without limitation sales taxes, are collected by Seller solely
          in the capacity of independent sales agent. Customer further grants to Seller a security
          interest in the Products to secure payment in full and authorizes Seller to execute and
          file a financing statement or other documents necessary to perfect such security interest.
          Any such security interest shall terminate upon payment of all amounts due for the
          applicable Products and any costs of collection.
        </p>
        <p>
          Product and Product Support Warranties. Notwithstanding anything to the contrary set forth
          herein or in any separate communication, the sole warranty received by Customer with
          respect to any Products and Product Support is the warranty, if any, that is conveyed
          directly from, respectively, the Product manufacturer or the Product Support provider and
          that will be passed through to Customer by Seller to the extent Seller has the right to do
          so. Seller, acting solely as a reseller of Products and a sales agent for Product Support,
          makes no independent warranty related to the Products or Product Support, sells such
          Products and Product Support "as is", and shall have no obligations related to the
          performance of the Products or the Product Support. Customer acknowledges that in
          purchasing the Products or Product Support, Customer relies solely on the applicable
          manufacturer specifications or the separate contract with the Product Support provider, as
          applicable, not on any communications from Seller, any Seller "Affiliate" (meaning an
          entity that owns, is owned by, or is under common ownership with, a Party) or its or their
          "Personnel" (meaning the owners, directors, officers, employees, agents or contractors of
          a Party), including without limitation any statements, specifications, photographs or
          other illustrations representing the Products or Product Support.
        </p>
        <h2>SERVICES SALES.</h2>
        <p>
          Services Purchasing. Subject to credit approval, Customer shall be entitled to purchase
          consulting and other services performed by Seller, Seller's Affiliate, or Seller's
          subcontractors (each a "Service"), according to the terms and conditions set forth
          generally in these Terms and Conditions and specifically in a "Service Order" or "SO"
          (meaning a written and executed contract that is between Seller or Seller's Affiliate and
          Customer or Customer's Affiliate and that specifies the terms and conditions under which
          one of the former will sell and provide Services to one of the latter).
        </p>
        <p>
          Customer Responsibilities. Customer agrees to cooperate reasonably at all times during
          Seller's performance of the Services, and Customer warrants that it will provide timely
          responses, approvals, authorizations, access and applicable information, as necessary
          ("Customer Support"). Customer further acknowledges and agrees that Seller's performance
          of the Services is dependent on Customer's timely and accurate fulfillment of the
          foregoing warranty obligations. Customer shall ensure that at all times during performance
          of Services at a site designated by Customer for such performance ("Customer Site"),
          Customer maintains adequate insurance coverage to protect Seller, Seller's Affiliate,
          Seller Personnel and the Customer Site, and Customer indemnifies and holds harmless
          Seller, Seller's Affiliate, Seller Personnel from any claims that arise out of any product
          liability, death, or damage to any person or property and that are not solely and
          proximately caused by Seller's gross negligence or willful misconduct.
        </p>
        <p>
          Services POs. Any PO issued for Services is intended for administrative purposes only.
        </p>
        <p>
          Services Acceptance. Seller may provide to Customer from time to time notification that
          certain among the Services and/or Work Product (as defined herein) are substantially
          complete. Upon receiving such notification, Customer has three (3) "Business Days"
          (meaning Monday through Friday, excluding Holidays) to evaluate such Services and/or Work
          Product and to indicate Customer's Rejection or Acceptance of them ("Acceptance Period"),
          as follows: (a) Customer rejects such Services and/or Work Product by submitting to Seller
          during the Acceptance Period a written document detailing with specificity each reason for
          which the Services and/or Work Product are rejected ("Rejection"); or (b) Customer accepts
          such Services and/or Work Product either by submitting to Seller a written and signed
          document on Seller's template, confirming its acceptance thereof, or by failing to reject
          such Services and/or Work Product prior to the expiration of the Acceptance Period
          ("Acceptance"). Seller shall have up to thirty (30) days after its receipt of a Rejection,
          or such longer period on which the Parties may agree ("Correction Period") to correct the
          rejected Services and/or Work Product and to provide notification that such Services
          and/or Work Product are again complete, thereby initiating a new Acceptance Period.
        </p>
        <p>
          Services Invoicing. Customer will be invoiced in accordance with the applicable SO;
          provided that Seller may invoice Customer for all Services under such SO or for a portion
          thereof.
        </p>
        <p>
          Services Warranty. Seller warrants that the Services will be performed and the Work
          Product will be prepared in a professional and workmanlike manner consistent with good
          practices in the information technology services industry. Customer's sole and exclusive
          remedy with respect to this warranty is for Seller, at Seller's sole option, either: (a)
          as applicable, to perform again any substantially failed Service or to prepare again any
          substantially failed Work Product; or (b) to refund the amount paid, as applicable, for
          any substantially failed Service or Work Product; provided that, with respect to either
          option, Customer notifies Seller in writing of any such substantial failure within five
          (5) Business Days after performance of such Service or delivery of such Work Product, and
          provided that the warranty with respect to any Service or Work Product is voided if: (i)
          such Work Product is used in conjunction with any hardware configuration, operating
          environment or computer program other than one expressly specified in the applicable SO;
          (ii) any portion of the Work Product is modified by anyone other than Seller or a party
          acting under Seller's direction; or (iii) the failure of the Work Product is due to any
          bugs, defects, problems or failures of hardware caused by the negligence or user error of
          Customer or any third party. Customer acknowledges that any estimates related to Services
          performance are non-binding and are intended for planning purposes only.
        </p>
        <p>
          Services Warranty Disclaimer. Seller disclaims all warranties: (a) that the work product
          will function other than as set forth in writing by seller; and (b) that the operation of
          the work product will be free from interruption or errors in all circumstances.
        </p>
        <p>
          Responsibility for Data. Seller shall have no responsibility for customer's data, and
          customer acknowledges and agrees that at all times during performance hereunder, customer
          is and shall remain solely responsible for risks to its data arising from, and for daily
          back-up and protection of its data, software and images against, loss, damage, corruption
          or destruction.
        </p>
        <p>
          License to Work Product. As between the Parties, Seller owns all rights, including without
          limitation all associated intellectual property rights, in the "Work Product" (meaning all
          deliverables, documents, other tangible items and Services results, including any
          inventions, innovations, improvements, other works of authorship, and other derivative
          works that arise therefrom and intellectual property therein or related thereto,
          conceived, developed or provided by Seller in the course or as a result of performing the
          Services, including from time to time third party intellectual property, regardless of
          whether they are eligible for patent, copyright, trademark, trade secret, or other legal
          protection). Nothing in these Terms and Conditions is intended to grant or transfer any
          ownership interest in the Work Product. Customer obtains under these Terms and Conditions
          and the applicable SO, upon payment in full, a non-exclusive, non-transferable, perpetual,
          royalty-free, license to use the Work Product for Customer's internal purposes only.
        </p>
        <p>
          Non-Solicitation. During the term of any SO and for one (1) year thereafter, each Party
          and its Affiliates may not directly or indirectly solicit for employment, offer employment
          to, employ or engage as a consultant any individual who either is then employed or was
          employed within the preceding three (3) months by the other Party or any of the other
          Party's Affiliates ("Employing Entity") and who was in any way engaged in or involved with
          the provision of Services under such SO unless and until such Party pays to the Employing
          Entity as liquidated damages and not as a penalty, an amount equal to the aggregate salary
          and wages (including bonus) paid by the Employing Entity to such individual during the six
          (6) months immediately preceding the date such individual is employed or engaged by such
          Party; provided that if such individual at the time he or she first discusses such
          employment or engagement with such Party is subject to or bound by any written employment
          agreement or non-competition covenant with the Employing Entity, this provision shall not
          authorize such Party to employ or engage such individual in violation of any such
          employment agreement or non-competition covenant or limit such Party's liability for
          damages in the event that such Party employs or engages such individual in violation
          thereof. Notwithstanding the foregoing, this provision shall not prohibit either Party
          from hiring any individual who initiates contact with such Party by responding to a
          general, non-targeted solicitation, such as a job posting in a mass publication or on a
          website.
        </p>
        <h2>PAYMENT</h2>
        <p>
          Payment. Customer will pay in full the amount specified as due on each undisputed invoice,
          including without limitation sales taxes and any shipping charges, within the time period
          specified on such invoice ("Payment Timing"), measured from the date of issuance of such
          invoice, subject to continuing credit approval of Customer. Notwithstanding the foregoing,
          Payment Timing is at Seller's sole discretion.
        </p>
        <p>
          Late Payment. In any event of Customer's failure to pay an undisputed amount due in
          accordance with these Terms and Conditions ("Payment Delinquency"), Seller has the right
          to charge and collect interest on such amount at the lesser of one-and-one-half percent
          (1½%) per month, and the highest rate legally permitted ("Interest Charge") until paid in
          full. Customer also agrees to pay the Interest Charge on any disputed amounts that are
          withheld by Customer in good faith and that are determined to be properly due and owing to
          Seller, calculating from the thirty-first (31st) day after the date of issuance of the
          original invoice. Customer shall reimburse Seller for all reasonable expenses arising from
          collection of past due amounts and Interest Charges, including reasonable attorneys' fees,
          filing fees, arbitration costs and court costs. Seller has the right to suspend
          performance hereunder during any Payment Delinquency, and any schedule will be deemed
          extended for the duration of such Payment Delinquency.
        </p>
        <p>
          Financial Status. If at any time during the term of the Transaction Document there is a
          detrimental material change in Customer's financial status, as determined in Seller's sole
          discretion, Seller has the right to modify the Payment Timing or terminate any credit
          extended to Customer under these Terms and Conditions. In the event Customer does not
          remit payment in accordance with these Terms and Conditions or such modified Payment
          Timing, Seller has the right immediately: (a) to stop all provision of Sales Offerings;
          and/or (b) to terminate any currently effective and not expired Transaction Document.
        </p>
        <p>
          Taxes. Customer agrees to pay all sales, use, transaction, excise, VAT or similar taxes
          and any Federal, state or local fees or charges ("Taxes") that may become due in
          connection with Customer's purchase of the Sales Offerings, except for taxes on Seller's
          income, assets, or net worth. In lieu thereof, Customer may provide to Seller a tax
          exemption certificate acceptable to Seller and the applicable taxing authority. Customer
          will reimburse Seller for all such Taxes paid by Seller for which Customer is responsible
          hereunder.
        </p>
        <p>
          Credits. Any credit that is issued to Customer for any reason and that is not used by
          Customer to purchase Sales Offerings within a period of two (2) month measured from the
          date of issuance shall expire.
        </p>
        <h2>CONFIDENTIALITY</h2>
        <p>
          Purpose of Disclosure. Each Party ("Discloser") may disclose certain of its "Confidential
          Information" (meaning information that is owned or possessed by a Party, its Affiliates or
          its or their Personnel, that either is marked as "confidential" or "proprietary" or
          otherwise due to its nature reasonably would be deemed to be confidential, and that is
          disclosed to, or accessed by, the other Party) to the other Party ("Receiver"), and
          Receiver agrees that it will only use Discloser's Confidential Information for the
          purposes contemplated hereunder.
        </p>
        <p>
          Non-Confidential Information. No information disclosed hereunder will be considered
          Confidential Information to the extent such information: (a) is in the public domain
          through no fault of a receiving Party; (b) was in the possession of the receiving Party
          prior to such disclosure; (c) was independently developed by the receiving Party's
          Personnel with no prior access to such information; or (d) was rightfully received by a
          Party from a third party without breach of any obligation of confidence.
        </p>
        <p>
          Receiver Disclosures Permitted. Each Party, as Receiver, further agrees that it will only
          disclose Discloser's Confidential Information to Receiver's Personnel or financial, legal
          or business advisors who are themselves bound to obligations of confidentiality
          substantially consistent with those specified herein; provided that if Receiver is
          compelled to disclose Discloser's Confidential Information by law, rule, regulation or
          judicial, regulatory or other governmental authority, Receiver will provide Discloser with
          prompt Notice of any such compulsion, will provide the maximum allowable opportunity for
          Discloser to seek a protective order or measure to bar such disclosure, will disclose only
          the Confidential Information that is required to be disclosed, and will make reasonable
          efforts to ensure that such disclosed Confidential Information is protected to the fullest
          extent possible under the circumstances.
        </p>
        <p>
          Period of Confidentiality. Each Party, as Receiver, agrees: (i) that, for a period of
          three (3) years from the date of any disclosure of Discloser's Confidential Information,
          it will maintain the confidentiality of such Confidential Information in a manner that is
          at least as protective as Receiver maintains its own confidential information, and that in
          no event will be inconsistent with a standard of reasonable care; and (ii) that, upon
          Discloser's request, it will return or destroy any extant Confidential Information
          disclosed by Discloser.
        </p>
        <p>
          Injunctive Relief. In the event of Receiver's breach of these Terms and Conditions,
          Discloser may suffer irreparable harm and have no adequate remedy at law. In such event,
          or when encountering risk of such event, Discloser shall be entitled, in addition to any
          and all other remedies, to seek injunctive relief, specific performance, and other
          equitable remedies without the need to prove monetary damages or the inadequacy of other
          remedies.
        </p>
        <h2>DISCLAIMED WARRANTIES</h2>
        <p>
          Except as set forth herein, and subject to applicable law, seller makes no other, and
          expressly disclaims all other, conditions, covenants, representations and warranties of
          every kind, whether express or implied, including without limitation any warranties or
          representations related to accuracy, durability, fitness for a particular purpose,
          merchantability, non-infringement, or applicability of laws or regulations associated with
          the products or their manufacture or packaging, arising by law, by reason of custom, from
          usage in the trade, or from course of prior dealing. Customer acknowledges and agrees that
          any information or advice given by seller or seller's affiliate or personnel shall not be
          deemed to create or in any way increase the scope of the warranties set forth herein and
          that customer shall not rely on any such information or advice. Seller further disclaims
          representations, warranties or assurances that the products are designed for, or suitable
          for use in: (a) any aircraft or automobile, including any safety or navigation devices
          related thereto; (b) any life support or other healthcare delivery systems or medical
          devices; (c) any nuclear facilities; (d) any weapon systems or other military or policing
          devices; or (e) any other high risk environment or manner that could reasonably be
          expected to result in personal injury, death or property damage ("high risk use").
          Customer agrees to comply with any manufacturer requirements and restrictions and to
          accept any manufacturer disclaimers related to any high risk use. customer takes sole
          responsibility for, and agrees to indemnify and hold harmless seller and seller's
          affiliates and personnel from any claim arising from, customer's high risk use.
        </p>
        <h2>LIMITATION OF LIABILITY</h2>
        <p>
          Under no circumstances, and notwithstanding the failure of essential purpose of any remedy
          set forth herein, will seller, its affiliates, or any of their personnel ever be liable
          for consequential, exemplary, incidental, indirect, punitive, or special damages
          (including, without limitation, loss of business, market, profits, revenues, savings, or
          goodwill) even if advised of the possibility of such damages or if such damages were
          otherwise foreseeable and regardless of the theory of liability. The maximum liability of
          seller, its affiliates and any of their personnel hereunder, arising from any theory of
          liability, will be the lesser of: (a) 50% of the total amount paid to seller for the
          specific sales offering(s) giving rise to the claim; and (b) twenty-five thousand dollars
          ($25,000).
        </p>
        <h2>ARBITRATION</h2>
        <p>
          Any dispute that arises from or under these Terms and Conditions or the applicable
          Transaction Document or that relates directly or indirectly to the relationship between
          the Parties and that cannot be resolved amicably within thirty (30) days shall be resolved
          by binding arbitration before a single arbitrator in accordance with the then-current
          Commercial Arbitration Rules of the American Arbitration Association. Judgment on any
          award arising from such arbitration may be entered in any court of competent jurisdiction.
          Discovery may be performed according to the Federal Rules of Civil Procedure. The fees and
          expenses of the arbitration shall be borne equally by the Parties but may be awarded,
          together with reasonable attorneys' fees and expenses, to the prevailing Party (if any) in
          the informed discretion of the arbitrator. Notwithstanding anything to the contrary set
          forth herein, either Party may seek injunctive or other equitable relief at any time in
          federal or state court. The forum for any such arbitration and for any such court shall be
          located in Dade County, Miami, Florida. Customer agrees that it shall not join or lead any
          class of claimants pursuing any claim related to the subject matter of this Agreement or
          the Transaction Document.
        </p>
        <h2>NOTICE</h2>
        <p>
          Any notice required or permitted under these Terms and Conditions shall be deemed valid
          and to have been duly given when: (i) delivered by hand with written confirmation of
          receipt; (ii) sent by facsimile with written confirmation of receipt; or (iii) delivered
          by a nationally recognized overnight delivery service with package tracking ("Notice").
          The address for Notice to Customer shall be either Customer's corporate headquarters or
          Customer's address set forth in the applicable Transaction Document; the address for
          Notice to Seller shall be as set forth below: Qameta Software s.r.o.
        </p>
      </div>
      <Footer1 />
    </div>
  </Layout>
);

export default TermsAndConditionsOfSale;
